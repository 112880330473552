import React from 'react';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


const history = createBrowserHistory();

const rootElement = document.getElementById('landing-page-event-form') || document.getElementById('root');

window.isLandingPage = rootElement.id !== 'root';
window.landingPagePath = window.location.pathname.split('/')[2];

console.log(`Version: ${process.env.REACT_APP_VERSION}`);

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

if(rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<React.StrictMode><App /></React.StrictMode>, rootElement);
} else {
  ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

